import React from 'react';
import { Grid } from '@material-ui/core';

import { Dialog, DialogHeader, Text } from '../../../design';
import { UserSubscriptionData } from '../../../types';
import styles from './UserSubscriptionsModal.module.scss';

interface UserSubscriptionsModalProps {
  subscriptionData: UserSubscriptionData;
  handleClose: () => void;
}

const UserSubscriptionsModal = ({
  subscriptionData,
  handleClose
}: UserSubscriptionsModalProps): JSX.Element => {
  return (
    <Dialog open={true} size="xlarge" handleClose={handleClose}>
      <DialogHeader title="Active Notifications" handleClose={handleClose} />
      <div className={styles.scrollableContent}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Text variant="body1" color="primary">
              Location
            </Text>
            {subscriptionData.subbedToAllLocations ? (
              <Text variant="body1">Subscribed to all locations</Text>
            ) : (
              subscriptionData.locationSubs.map(
                sub =>
                  sub.account && (
                    <Text key={sub.id} variant="body1">
                      {sub.account?.name}
                    </Text>
                  )
              )
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Text variant="body1" color="primary">
              Power
            </Text>
            {subscriptionData.subbedToAllPowersources ? (
              <Text variant="body1">Subscribed to all locations</Text>
            ) : (
              subscriptionData.powersourceSubs.map(
                sub =>
                  sub.account && (
                    <Text key={sub.id} variant="body1">
                      {sub.account?.name}
                    </Text>
                  )
              )
            )}
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
};

export default UserSubscriptionsModal;
