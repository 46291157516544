import React from 'react';
import { IconButton } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';

import useUsers from 'api/useUsers';
import PageHeader from 'app/components/PageHeader';
import { useDashboardSlice } from 'app/layouts';
import { MARKETSPARK, pageSizeOptions } from 'constants/app';
import { ColumnProps, Link, SearchInput, TablePaginated } from 'design';
import useSort from 'hooks/useSort';
import UserActions from './UserActions';
import useRolesCount from 'api/useRolesCount';
import RolesBadgeBar from 'app/components/RolesBadgeBar';
import { RoleName, User, UserSubscriptionData } from 'types';
import { selectCurrentUser } from '../../../store/selectors';
import UserSubscriptionsModal from '../../components/UserSubscriptionsModal';
import style from './UsersPage.module.scss';

const UsersPage = (): JSX.Element => {
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();
  const currentUser = useSelector(selectCurrentUser);

  const [filter, setFilter] = React.useState('');
  const [rolesFilter, setRolesFilter] = React.useState<RoleName | ''>('');
  const [searchInput, setSearchInput] = React.useState('');
  const [sort, setSort] = useSort();
  const [pageSize, setPageSize] = React.useState(10);
  const [pageIndex, setPageIndex] = React.useState(1);
  const [subscriptionData, setSubscriptionData] = React.useState<
    UserSubscriptionData
  >({
    locationSubs: [],
    powersourceSubs: [],
    subbedToAllLocations: false,
    subbedToAllPowersources: false
  });
  const [subscriptionModalOpen, setSubscriptionModalOpen] = React.useState(
    false
  );

  const { isLoading, data, error } = useUsers(
    filter,
    rolesFilter,
    sort,
    pageIndex,
    pageSize
  );
  const { users, total } = data || { users: [], total: 0 };
  const {
    data: counts,
    isLoading: isRoleCountLoading,
    error: rolesCountError
  } = useRolesCount();

  if (error) {
    throw new Error(`${error.message} Failed to fetch Users!`);
  }

  /**
   * Dispatch dialog to invite user
   */
  const handleAddUser = React.useCallback(() => {
    dispatch(
      actions.showDialog({
        open: true,
        size: 'xlarge',
        title: 'Invite Users',
        lazyComponent: 'UsersInvite'
      })
    );
  }, [actions, dispatch]);

  // Handle notification open
  const handleNotificationOpen = (user: User) => {
    setSubscriptionData({
      locationSubs: user.locationSubs ?? [],
      powersourceSubs: user.powersourceSubs ?? [],
      subbedToAllLocations: user.subbedToAllLocations ?? false,
      subbedToAllPowersources: user.subbedToAllPowersources ?? false
    });
    setSubscriptionModalOpen(true);
  };

  const columns = React.useMemo<ColumnProps[]>(
    () => [
      { accessor: 'id', label: 'id', hidden: true },
      {
        accessor: 'isPendingConfirm',
        label: 'Status',
        sortable: true,
        resizable: true,
        hidden: !counts?.pending,
        render: (user: User) => (
          <>{!user.isPendingConfirm ? 'Pending' : 'Active'}</>
        )
      },
      {
        accessor: 'notificationSubscriptions',
        label: 'Notifications',
        sortable: false,
        resizable: true,
        hidden: currentUser.organization.name === MARKETSPARK,
        render: (user: User) => {
          const isActive =
            user.locationSubs?.length || user.powersourceSubs?.length;
          return (
            <>
              {isActive ? (
                <div
                  className={style.isActive}
                  onClick={() => handleNotificationOpen(user)}
                >
                  Active
                </div>
              ) : (
                'Inactive'
              )}
            </>
          );
        }
      },
      {
        accessor: 'firstName',
        label: 'First Name',
        sortable: true,
        resizable: true
      },
      {
        accessor: 'lastName',
        label: 'Last Name',
        sortable: true,
        resizable: true
      },
      {
        accessor: 'username',
        label: 'Email',
        sortable: true,
        resizable: true
      },
      {
        accessor: 'phoneMobile',
        label: 'Contact number',
        sortable: true,
        resizable: true
      },
      {
        accessor: 'orgPosition',
        label: 'Title',
        sortable: true,
        resizable: true
      },
      {
        accessor: 'organization.name',
        label: 'Organization',
        sortable: true,
        hidden: !currentUser?.isMSOrg,
        render: ({ organization }: User) => (
          <Link to={`/organization/${organization?.id}`}>
            {organization?.name}
          </Link>
        ),
        resizable: true
      },
      {
        accessor: 'primaryRole.name',
        label: 'Role',
        sortable: true,
        resizable: true
      },
      {
        accessor: '',
        label: '',
        hidden: currentUser?.isUser,
        render: (user: User) => <UserActions user={user} />,
        sortable: false
      }
    ],
    [currentUser, counts]
  );

  return (
    <>
      <Helmet>
        <title> Users </title>
        <meta
          name="description"
          content="MarketSpark - Command Center"
          data-rh="true"
        />
      </Helmet>
      <div className={style.headWrapper}>
        <PageHeader title="Users" />
        <div className={style.optionsWrapper}>
          <SearchInput
            className={style.searchInput}
            value={searchInput}
            handleClear={() => {
              setFilter('');
              setSearchInput('');
              setPageIndex(1);
            }}
            onChange={setSearchInput}
            handleSearch={() => {
              setFilter(searchInput);
              setPageIndex(1);
            }}
          />
          {!currentUser?.isUser && (
            <IconButton
              onClick={handleAddUser}
              data-testid="addButton"
              classes={{ root: style.addUserButton }}
            >
              <AddCircleIcon
                color="primary"
                classes={{ root: style.addUserIcon }}
              />
            </IconButton>
          )}
        </div>
      </div>
      <RolesBadgeBar
        countData={
          counts ?? {
            superAdmin: 0,
            orgAdmin: 0,
            locationAdmin: 0,
            user: 0,
            total: 0,
            pending: 0
          }
        }
        isLoading={isRoleCountLoading}
        error={rolesCountError}
        rolesFilter={rolesFilter}
        setRolesFilter={setRolesFilter}
        isMsOrg={currentUser?.isMSOrg ?? false}
      />
      <div>
        <TablePaginated
          id="users"
          rows={users}
          sort={sort}
          total={total}
          columns={columns}
          handlePageSize={setPageSize}
          handlePageNav={setPageIndex}
          pageIndex={pageIndex}
          pageSize={pageSize}
          pageSizeOptions={pageSizeOptions}
          handleSort={setSort}
          isLoading={isLoading}
        />
      </div>
      {subscriptionModalOpen && (
        <UserSubscriptionsModal
          subscriptionData={subscriptionData}
          handleClose={() => setSubscriptionModalOpen(false)}
        />
      )}
    </>
  );
};

export default UsersPage;
