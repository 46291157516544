import React from 'react';

import { DialogCloseIcon, Text } from '../../design';
import styles from './Dialog.module.scss';

interface DialogHeaderProps {
  title: string;
  handleClose: () => void;
}

const DialogHeader = ({
  title,
  handleClose
}: DialogHeaderProps): JSX.Element => {
  return (
    <div className={styles.modalHeaderWrapper}>
      <Text variant="h5">{title}</Text>
      <DialogCloseIcon onReset={handleClose} />
    </div>
  );
};

export default DialogHeader;
