import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MoreVert from '@material-ui/icons/MoreVert';

import { selectCurrentUser } from '../../../store/selectors';
import { MoreMenu, ActionButton } from 'design';
import { useDashboardSlice } from 'app/layouts';
import { mixnetworks } from 'constants/app';
import { MARKETSPARK_ORG_NAME } from 'constants/app';

interface LineActionsProps {
  id: string;
  equipmentId: string;
  phoneNumber: number;
  forwardValue: number | null;
  forwardEnable: boolean;
  providerName?: string | null;
  locationName?: string;
  locationErpId?: string;
  refetch?: any;
  e911Address?: any;
  pathName: string;
}

const LineActions = ({
  id,
  phoneNumber,
  forwardValue,
  forwardEnable,
  providerName,
  locationName,
  locationErpId,
  refetch,
  e911Address
}: LineActionsProps): JSX.Element => {
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const lineMoreMenuItems = [
    {
      label: 'Call Forwarding',
      isVisible: providerName === mixnetworks,
      // DEV dispatch modal!
      action: () => {
        dispatch(
          actions.showDialog({
            open: true,
            title: 'Call Forwarding',
            lazyComponent: 'CallForwardingEdit',
            lazyComponentProps: {
              lineId: id,
              phoneNumber,
              forwardValue,
              forwardEnable
            }
          })
        );
      }
    },
    {
      label: 'Delete',
      isVisible:
        currentUser?.isSuperAdmin && locationName === MARKETSPARK_ORG_NAME,
      action: () => {
        dispatch(
          actions.showDialog({
            open: true,
            title: '',
            lazyComponent: 'LineDelete',
            lazyComponentProps: {
              id,
              phoneNumber,
              refetch
            }
          })
        );
      }
    },
    {
      label: 'Emergency Address',
      isVisible:
        (currentUser?.isSuperAdmin ||
          currentUser?.isOrgAdmin ||
          currentUser?.isLocationAdmin) &&
        locationName !== MARKETSPARK_ORG_NAME,
      action: () => {
        dispatch(
          actions.showDialog({
            open: true,
            title: 'Edit Emergency Address for Line',
            size: 'xlarge',
            lazyComponent: 'LinesE911',
            lazyComponentProps: {
              id,
              e911Address
            }
          })
        );
      }
    },
    {
      label: 'Edit',
      isVisible:
        (currentUser?.isSuperAdmin ||
          currentUser?.isOrgAdmin ||
          currentUser?.isLocationAdmin) &&
        locationName !== MARKETSPARK_ORG_NAME,
      action: () => {
        dispatch(
          actions.showDialog({
            open: true,
            title: 'Edit Line',
            size: 'large',
            lazyComponent: 'LocationLineUpdate',
            lazyComponentProps: {
              id,
              erpLocationId: locationErpId,
              refetch
            }
          })
        );
      }
    },
    {
      label: 'Detach',
      isVisible:
        currentUser?.isSuperAdmin && locationName !== MARKETSPARK_ORG_NAME,
      action: () => {
        dispatch(
          actions.showDialog({
            open: true,
            title: 'Detach Line',
            lazyComponent: 'LineDetach',
            lazyComponentProps: {
              id,
              phoneNumber,
              locationName,
              refetch
            }
          })
        );
      }
    }
  ];
  return (
    <>
      <div style={{ float: 'right' }}>
        <ActionButton onClick={handleMenuClick}>
          <MoreVert color="action" />
        </ActionButton>
      </div>
      <MoreMenu
        anchorEl={anchorEl}
        handleClose={handleMenuClose}
        menuItems={lineMoreMenuItems}
      />
    </>
  );
};

export default LineActions;
